<template>
<div class="wrapper">
<!-- Navbar --> 
<topbar2></topbar2>
    <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <header2></header2>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="">
         <div class="row">    
              <div class="col-md-12">
                 <div class="card-header">
                <h3 class="card-title"> Change Password </h3>
              </div>
              </div>              
            </div>   
       
      </div><!-- /.container-fluid -->
    </div>
    
        <!-- Main content -->
    <section class="content">
      <div class="container-fluid">        
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-6 connectedSortable">
            <!-- Custom tabs (Charts with tabs)-->
            <div class="card">            
              <div class="card-body">
                <div class="tab-content p-0">
                  <!-- Morris chart - Sales -->
                  <div class="chart tab-pane active">
                      <div class="servicelistdesign">   
                          
        <label>Old Password</label>
        <input type="password" class="form-control" placeholder="Old Password" name="oldpassword" id="oldpassword" v-model="oldpassword"/>
        <br>
        <label>New Password</label><br>
        <input type="password" class="form-control" placeholder="New Password" name="newpassword" id="newpassword" v-model="newpassword"/>
        <br>        
        <button v-on:click="submit()" class="btn btn-success">Submit</button>
    </div>
                   </div>                  
                </div>
              </div><!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.Left col -->
        </div>
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>


  
  </div>
  <footer2></footer2> 
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Topbar from './Topbar.vue'
import axios from "axios";
export default {
  extends:defineComponent,
    components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar,         
      },
  name: 'Tutorial',  
    data(){
        return {
            //id: this.$route.params.id,
            user_id:this.$store.state.auth.user.data[0].id,           
            oldpassword: '',
            newpassword: ''            
        }
    },

    methods: {
      showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
        submit(){
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/changepassword', {user_id: this.user_id,oldpassword: this.oldpassword,newpassword: this.newpassword} , {
              user_id: this.user_id,              
            })
               .then(response => {   
                  this.showsuccessmsg(response.data.response);                  
                  console.log(response.data.response);                               
                  //this.$router.push({path:'/changepassword'})
                  //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });
        }
    }
}
</script>
<style>
  @import '../assets/dist/css/custom.css'  
</style>